import type { GetServerSidePropsContext } from 'next';

import { GenericPageLayout } from '@/components/layout/layout.page.component';
import { getDefaultServerSideProps } from '@/components/layout/layout.page.props';

export const getServerSideProps = async (
  initialProps: GetServerSidePropsContext,
) => {
  return await getDefaultServerSideProps(initialProps);
};

export default GenericPageLayout;
